function ready(fn)
{
    if (document.readyState !== "loading")
    {
        fn();
    } else if (document.addEventListener)
    {
        document.addEventListener("DOMContentLoaded", () => fn())
    } else
    {
        let done = false
        document.attachEvent("onreadystatechange", () =>
        {
            if (!done && document.readyState !== "loading")
            {
                done = true
                fn()
            }
        })
    }
}

export default ready
