import ready from "./utils/ready"
import $$ from './utils/$$'

ready(() =>
{
    $$('.no-js,html').forEach(el =>
    {
        el.classList.remove('no-js')
        el.classList.add('js')
    })
})
