import ready from "./utils/ready";
import debounce_raf from "./utils/debounce_raf";

ready(() =>
{
    let prev_scroll_y = -1
    let classes = document.querySelector('html').classList

    const update = debounce_raf(() =>
    {
        const scroll_y = window.scrollY
        if (scroll_y !== prev_scroll_y)
        {
            console.log({prev_scroll_y, scroll_y})
            classes.toggle('at-top', scroll_y === 0)
            classes.toggle('not-top', scroll_y !== 0)
            prev_scroll_y = scroll_y
        }
    })
    window.addEventListener('scroll', update)
    window.addEventListener('orientationchange', update)
    window.addEventListener('resize', update)
    update()
})