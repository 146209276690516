let request = requestAnimationFrame
let clear = cancelAnimationFrame

function debounce_raf(func)
{
    let timer
    return (...args) =>
    {
        clear(timer)
        timer = request(() =>
        {
            func.apply(this, args)
        })
    }
}

export default debounce_raf