import $$ from './utils/$$'
import balanceText from 'balance-text'
import ready from "./utils/ready"

// https://developer.mozilla.org/en-US/docs/Web/CSS/text-wrap#browser_compatibility
balanceText()
ready(async () =>
{
    $$('.child\\:text-balance>*,.text-balance').forEach(el =>
    {
        balanceText(el)
        console.log('ran balanceText on ', el)
    })

    console.log('ran balanceText')
})