import ready from "./utils/ready"
// import loadCSS from "./utils/loadCSS"
import $$ from './utils/$$'

// const splide_module = import('https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/+esm')
// const splide_css_url = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/css/splide-core.min.css'

const splide_js_promise = import('@splidejs/splide')
const splide_css_promise = import('@splidejs/splide/dist/css/splide-core.min.css')
const splide_selector = '.splide:not(.is-initialized)'

$$(splide_selector).forEach(async () =>
{
    // await loadCSS(splide_css_url)
    await splide_css_promise
    await splide_js_promise
})
ready(() =>
{
    $$(splide_selector).forEach(async splide_el =>
    {
        // await loadCSS(SplideCSS)
        await splide_css_promise
        const {Splide} = await splide_js_promise
        const splide = new Splide(splide_el)
        splide.mount()
    })
})