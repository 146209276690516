/**
 *
 * @param obj
 * @returns {Array}
 */
function toArray(obj)
{
    return Array.prototype.slice.call(obj)
}

export default toArray